import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import EspaciadorVertical from '../components/espaciador-vertical'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Relatos } from '../components/estilos/estilos-de-articulo'
import Comentarios from '../components/comentarios'
import FotosYCarrousel from '../components/fotos-y-carrousel'
import styled from '@emotion/styled'
import Navegar from '../components/navegar'

const TituloDelCarrousel = styled.div`
  width: 90%;
  margin: 4rem auto;
  color: white;
  font-family: Satisfy;
  font-size: 48px;
  text-shadow: 1px 1px 2px #16455a;
`
const EnvoltorioDelCarrousel = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`
// #region GraphQL
export const query = graphql`
  {
    allListaCefaluYaml {
      edges {
        node {
          nombre
          orgsrc
          mbdurl
          dscrpt
          origen {
            childImageSharp {
              fixed(height: 100) {
                ...GatsbyImageSharpFixed
              }
              fluid(fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRelatosCefaluYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const RelatosCefalu = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFixed = nombre => {
    const edges = data.allRelatosCefaluYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fixed = found.node.origen.childImageSharp.fixed
    return fixed
  }
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allRelatosCefaluYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }

  return (
    <div>
      <Marco>
        <Relatos>
          <Container maxWidth="xl">
            <Minipaginas>
              <Minipagina>
                <EspaciadorVertical altura={12} />
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto02CefaluPlayaybotesjpg')}
                  title="Cefalú, playa y botes."
                  leyenda="Cefalù. Montañas, casas, playa y botes."
                />
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h2>Descubriendo Cefalù, Sicilia</h2>
                  <p>
                    Por suerte fui a Cefalù ¡Qué bello que es! Me alojaba en Palermo y decidí
                    dedicar medio día para andar en tren a conocer Cefalù y volver a la noche.
                  </p>
                  <p>
                    Como siempre me sucede, me resultó muy poco tiempo!!! Sería lindo quedarme una o
                    dos noches…
                  </p>
                  <p>
                    Llegué a la estación y comencé a caminar hacia la parte histórica que está muy
                    cerca. En el caso histórico me encontré en medio de hermosas callecitas llenas
                    de historia. Las montañas de rocas por un lado, con un castillo en la cima de
                    una de ellas, llamado Rocca di Cefalù, y el mar por el otro lado.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>Me fascinó la arquitectura.</h3>
                  <p>
                    Se supone que el origen de Cefalù es fenicio. Pero es muy ecléctica debido a las
                    distintas civilizaciones que la han habitado. Entre éstas los Normandos que han
                    dejado hermosas edificaciones.
                  </p>
                  <p>
                    Caminando caminando… Oh sorpresa ¡qué belleza! La Catedral!!! Es normanda de
                    estilo bizantino.
                  </p>
                  <h3>Leyenda del origen de la Catedral</h3>
                  <p>
                    Cuenta la legenda, que el conquistador normando Ruggero II d’Altavilla, re di
                    Sicilia, Puglia e Calabria, después de escapar de una tempestad llegó sano y
                    salvo a Cefalú. Para cumplir con el voto por la gracia recibida ordenó la
                    construcción de la Catedral, allá por el año 1131.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto03CatedralCefalujpg')}
                  title="Cefalú, Catedral. A gloria del SS. Salvatore e dei Santi Pietro e Paolo."
                  leyenda="Cefalù. Catedral. 'A gloria del SS. Salvatore e dei Santi Pietro e Paolo.'"
                />
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto04CefaluCafeycannolosicilianojpg')}
                  title="Cefalù. Caffè e cannolo siciliano."
                  leyenda="Cefalù. Caffè e cannolo siciliano.'"
                />
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>Cannolo siciliano</h3>
                  <p>
                    Llegando al centro histórico... lo primero es lo primero, a reponer fuerzas!!!
                    ¿Cómo? con un buen expresso, cannoli siciliani y Coca Cola… qué mal Susana, esto
                    de la Coca Cola es imperdonable...lo admito!!!!
                  </p>
                  <p>
                    Ojo, a no ofender a los sicilianos muy orgullosos por tener de las mejores
                    pastelerías del Mundo, famosísima.
                  </p>
                  <p>
                    ¡Quiero decir que mejor no se te ocurra pedir una especialidad de otra región,
                    se ofenden mucho y con razón! Le pasó a una prima mía, pidió una especialidad
                    napolitana, se ofendió tanto el dueño de la «pasticceria» que no le vendió nada.
                    Mi prima habla italiano, el hombre habrá pensado que era italiana. Fue gracioso,
                    porque el pastelero le preguntó si sabía "dónde" estaba, ella le respondió que
                    en «Sicilia», y esto lo preguntó para hacerle notar que lo que pedía era de otra
                    Región.
                  </p>
                  <p>
                    Realmente las especialidades sicilianas son exquisitas. Muero por la cassata
                    siciliana, no es un helado, es una especie de masita en base a almendras.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>En el Centro histórico.</h3>
                  <p>
                    Caminé unas nueve cuadras hasta llegar a la Catedral. O sea, que está ahí nomás
                    de la estación de trenes. En el camino vi la Iglesia del Purgatorio y en la
                    Piazza Garibaldi vi la torre con el reloj.
                  </p>
                  <p>
                    La Piazza del Duomo, frente a la Catedral, es un lugar muy pintoresco, con
                    confiterías con mesitas, palmeras, macetones con flores, negocios de souvenirs,
                    edificios públicos, distintos estilos arquitectónicos, en fin, de todo un poco
                    ¡Y reina el sol! Subiendo una alta y ancha escalinata se llega al atrio de la
                    Catedral al que se entra a través de un portón de hierro forjado. Está rodeado
                    de un bellísimo enrejado.
                  </p>
                  <p>
                    El casco histórico está encajonado por un lado por el mar y por el otro por una
                    montaña de roca absolutamente vertical, es increíble. No tuve tiempo de subirla.
                    Hay un camino para llegar a la cumbre y visitar las ruinas de un castillo
                    llamado Rocca. Quedará pendiente para otro viaje.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto05CefaluPiazzaGaribaldiOrologiojpg')}
                  title="Cefalù. Piazza Garibaldi. Orologio."
                  leyenda="Cefalù. Piazza Garibaldi. Orologio."
                />
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto06CefaluMuelleyRoccajpg')}
                  title="Cefalù. El muelle y la Rocca."
                  leyenda="Cefalù. El muelle y la Rocca."
                />
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>En la costa.</h3>
                  <p>
                    Algunas casas están directamente sobre el mar porque hay partes en que no hay
                    playa. Puedes verlas en la foto. Cerca del muelle hay una playita donde
                    descansan pintorescos botes de colores. En cuanto descubrí un banco vacío, de
                    esos que están en el muelle, de frente al mar, me senté a ver ese increíble
                    paisaje ¡Qué paz! ¡Todo tan romántico! Claro que no tuve que esperar mucho
                    tiempo para que se me acercara un italiano a charlar e invitarme a un café.
                    Rechacé la oferta. Me causó gracia. Seguí caminando por el muelle. Encontré unas
                    barras de hierro llenas de candados oxidados ¿También los amores se habrán
                    oxidado?
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>Il tramonto</h3>
                  <p>
                    Luego volví hacia el casco histórico para recorrer otras callecitas, más
                    comerciales que las que ya había recorrido. Y, lentamente acercarme a la
                    estación para tomar el tren. Pero esta vez por la costanera con rambla. Esta
                    parte está fuera del casco histórico, la edificación es nueva y tiene unas
                    hermosas playas muy anchas. Y allí me encontró «il tramonto« (el atardecer). Es
                    hermoso el color dorado que adquiere Cefalù al atardecer. Lo puedes apreciar en
                    la foto.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto07CefaluVistajpg')}
                  title="Cefalù. Vista desde la playa ancha en la parte moderna de la ciudad."
                  leyenda="Cefalù. Vista desde la playa ancha en la parte moderna de la ciudad."
                />
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto08Cefalucentrohistoriconochejpg')}
                  title="Cefalù. Centro histórico de noche."
                  leyenda="Cefalù. Centro histórico de noche."
                />
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>¡Peripecias con el tren!</h3>
                  <p>
                    Llego a la estación con tiempo como para beber un cafecito en el bar de la
                    estación, de pie porque no hay donde sentarse, y tomar el tren ¡Tomar el tren!
                    Eso creía!!! Pero confirmé la información que había leído en los blogs de otros
                    viajeros, en Sicilia no se puede confiar en los medios de transporte. Son un
                    desastre. No cumplen los horarios, los cancelan sin aviso ¡Socorro! En la
                    pantalla decía que mi tren tenía una demora de 15 minutos. Me siento en la
                    estación a esperar, comenzaba a hacer frío porque ya era de noche y había
                    viento. Corrigen la información ¡Ahora la demora es de 50 minutos! ¡Si lo
                    hubiera sabido antes me quedaba en el centro histórico a cenar! Como veo que va
                    para largo vuelvo al bar para ver qué puedo comer y … estaban cerrando!!!
                    Solamente pude comprar una bolsita de papas fritas. Decidí volver al centro
                    histórico ya que no había nada de nada en la estación. Gracias a eso pude
                    conocer Cefalù de noche y la cosa sorprendente es que estaba lleno de gente.
                    Tenía mucha vida, mucho movimiento. Muy alegre. Me gustó mucho.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <TextoEnMinipagina>
                  <h3>Sucedió un 21 de marzo de 2017</h3>
                  <p>
                    Volví a la estación y ahora mi tren tenía un atraso de 1 hora y quarto!!! ¿Podés
                    creerlo? ¡Habría podido cenar! Pero vi que estaba por llegar otro tren que
                    también iba a Palermo, así que tras una espera de algunos minutos tomé ese. Y me
                    comí las papas fritas para ir tirando. La cena fue en Palermo.
                  </p>
                  <p>
                    A continuación agrego más fotos. El motivo de agregar más fotos es porque tal
                    vez te ocurra como a mí. Cuando quiero hacerme una idea de cómo es un lugar para
                    decidir visitarlo, busco fotos en la Web y encuentro siempre las mismas fotos
                    repetidas, fotos de los lugares turísticos, de monumentos, iglesias, en fin, no
                    me hago una idea de cómo es el lugar, de qué cosa voy a encontrar al llegar. Y
                    como yo saco fotos a cada paso, bueno, las organicé en el orden en que iba
                    caminando y descubriendo los sitios. Me gusta sacar fotos de cosas que me llamen
                    la atención, por ejemplo, el auto intentando girar en una callecita estrecha,
                    los candados oxidados dejados por los amantes, los árboles saliendo de las
                    paredes de las casas, la decoración de los negocios de artesanías, las vidrieras
                    con las artesanías, la gente tomando sol en la escalinata de la catedral, otros
                    sentados admirando el paisaje, la Rocca con los árboles arriba, las calzadas de
                    travertino con ese brillo tan particular. En fin, miles de detalles que hacen de
                    Cefalù un lugar mágico.
                  </p>
                </TextoEnMinipagina>
              </Minipagina>
              <Minipagina>
                <FotoConLeyenda
                  zoom
                  fluid={toFluid('foto09Cefalucentrohistoriconochejpg')}
                  title="Cefalù. Bar, restaurante Helios, de noche. Via Giacomo Matteotti."
                  leyenda="Cefalù. Bar, restaurante Helios, de noche. Via Giacomo Matteotti."
                />
              </Minipagina>
            </Minipaginas>
            <EnvoltorioDelCarrousel>
              <TituloDelCarrousel>
                ...por eso mientras sueñas con tu viaje a Cefalú puedes hacerte una idea de cómo es
                mirando mis fotos...
              </TituloDelCarrousel>
              <FotosYCarrousel edges={data.allListaCefaluYaml.edges} />
            </EnvoltorioDelCarrousel>
            <Navegar location={location} />
            <Comentarios />
            <EspaciadorVertical altura={36} />
          </Container>
        </Relatos>
      </Marco>
    </div>
  )
}
export default RelatosCefalu
